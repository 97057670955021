<template>
  <div name="assessmentReport">
    <section
      class="grid grid-flow-col gap-2 sm:auto-cols-min rounded w-full pt-2 mb-8"
      v-bind:class="isDashboardView === true ? 'grid-cols-1' : 'grid-cols-2'"
    >
      <div class="p-2 pb-4 bg-white" v-if="!isDashboardView">
        <h4 class="text-center panel-header">
          Overall Assessment
        </h4>
        <barChart
          v-if="componentLoaded"
          :data-source="assessmentCount"
        ></barChart>
        <p v-else>We could not find any data for your given selection.</p>
      </div>

      <div class="p-2 bg-white">
        <h4 class="text-center panel-header">
          Working at an expected level
        </h4>

        <horizontalBarChart
          v-if="
            workingAt.filter((x) => {
              return x.value > 0;
            }).length > 0
          "
          :data-source="workingAt"
          :percentage="true"
          :totalOveride="assessmentData.length"
        ></horizontalBarChart>
        <p v-else>We could not find any data for your given selection.</p>
      </div>
    </section>
  </div>
</template>

<script>
const horizontalBarChart = () =>
  import("@/components/charts/HorizontalBarChart.vue");
const barChart = () => import("@/components/charts/BarChart.vue");
import { assessmentMixin } from "@/mixins/assessmentMixin.js";

export default {
  name: "assessmentReport",
  mixins: [assessmentMixin],
  props: {
    isDashboardView: { type: Boolean, required: false },
    assessmentData: { type: Array, required: true },
  },
  computed: {
    componentLoaded() {
      return this.assessmentData.length > 0;
    },
    assessmentCount() {
      let data = this.grades.map((grade) => {
        var filtered = this.assessmentData.filter((assessment) => {
          let filtered = true;

          filtered =
            assessment.grade >= grade.low && assessment.grade < grade.high;
          return filtered;
        });

        return {
          name: grade.title,
          value: filtered.length,
          color: grade.color,
        };
      });

      return data;
    },
    workingAt() {
      let data = this.expectancies.map((expectancy) => {
        var filtered = this.assessmentData.filter((assessment) => {
          let filtered = true;

          filtered =
            assessment.grade >= expectancy.low &&
            assessment.grade < expectancy.high;
          return filtered;
        });

        //  console.log("FILTE LEB", expectancy.title, filtered.length);

        return {
          name: expectancy.title,
          value: filtered.length,
          color: expectancy.color,
        };
      });

      return data;
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  components: {
    horizontalBarChart,
    barChart,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.titlebar {
  @apply text-sm font-semibold mb-6 w-full text-center;
}
</style>
